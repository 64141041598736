<template>
  <div class="messageDetail-container">
    <div class="messageDetail-box">
      <div class="messageDetail-box-top">我的消息</div>
      <div class="messageDetail-box-top-detail">
        <div class="messageDetail-box-top-detail-div">
          <p class="messageDetail-box-top-detail-div-p1">{{detailData.typeName}}消息</p>
          <p class="messageDetail-box-top-detail-div-p2">{{detailData.msgContent}}</p>
          <img :src="require('/static/images/detailimg.png')" alt="">
          <p class="messageDetail-box-top-detail-div-p3"></p>
          <p class="messageDetail-box-top-detail-div-p4">{{detailData.createTime}}</p>
          <p @click="backup" class="messageDetail-box-top-detail-div-p5">返回上一页</p>
        </div>
      </div>
    </div>
    <div style="clear:both;"></div>
  </div>
</template>

<script>
export default {
  name: 'messageDetail',
  data(){
    return{
      type:this.$route.query.type,
      detailData:{}
    }
  },
  components: {

  },
  methods:{
    getdata(){
      this.$http.post(this.GLOBAL.new_url+'/msg/queryMsg',{
                userCode:this.$cookie.getCookie('u_id'),
                msgRead:'',
                pageNum:1,
                pageSize:1000
            },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                if(res.data.code == "200"){
                  for(var i =0;i<res.data.data.list.length;i++){
                    if(this.$route.query.id == res.data.data.list[i].msgCode){
                      this.detailData = res.data.data.list[i];
                    }
                  }
                  // console.log(this.detailData)
                  this. getstatus()
                }
            })
    },
    backup(){
      this.$router.push({
          name: 'message',
          params:{
            type:this.type
          }
        });
    },
    getstatus(){
      this.$http.post(this.GLOBAL.new_url+'/msg/readMsg',{
                msgCode:this.$route.query.id
            },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                                  // console.log(res)
                                })
    }
  },
  mounted(){
    this.getdata()
  }
}

</script>

<style scoped>
  .messageDetail-box{
        width: 1015px;
        float: left;
        margin: 12px 0 0 16px;
        min-height: 950px;
        background: #fff;
    }
    .messageDetail-box-top{
      font-family: "PingFangSC-Thin";
      font-size: 22px;
      font-weight: 100;
      color: #999999;
      margin: 12px 0 14px 105px;
    }
    .messageDetail-box-top-detail{
      width: 740px;
      margin: 0 auto;
      padding:13px 30px 0 35px;
      border-top:1px solid #d8d8d8;
    }
    .messageDetail-box-top-detail-div-p1{
      font-family: "PingFangSC-Semibold";
      font-size: 20px;
      font-weight: 600;
      color: #333333;
      margin-bottom: 10px;
    }
    .messageDetail-box-top-detail-div-p2{
      font-family: "PingFangSC-Regular";
      font-size: 16px;
      color: #333333;
      margin-bottom: 12px;
    }
    .messageDetail-box-top-detail-div-p3{
      font-family: "PingFangSC-Regular";
      font-size: 12px;
      color: #666666;
      margin: 62px 0 10px;
    }
    .messageDetail-box-top-detail-div-p4{
      font-family: "PingFangSC-Thin";
      font-size: 12px;
      font-weight: 100;
      color: #999999;
      margin-bottom: 24px;
    }
    .messageDetail-box-top-detail-div-p5{
      font-family: "PingFangSC-Regular";
      font-size: 12px;
      color: #EB5E00;
      margin-bottom: 62px;
      cursor: pointer;
    }
</style>
